.fieldset {
  border-radius: 15px;
  font-size: 24px;
  font-weight: bold;
  padding-inline: 20px;
  padding-bottom: 10px;
  width: 900px;
}

.legend {
  width: fit-content !important ;
  margin-bottom: 0px !important;
  padding-inline: 10px !important;
  border-radius: 15px;
  font-weight: 850;
  font-size: 28px !important;
}
.content {
  display: flex !important;
  flex-direction: column !important;
  width: fit-content !important;
  font-weight: 540 !important;
}
