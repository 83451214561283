.container {
  display: inline-block;
}
.icon_container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid white;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 5px;
  background-color: #64a0b6;
}

.icon_container_content {
  font-size: 19px;
  margin-left: 4px;
  margin-top: 1px;
}
