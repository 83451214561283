.container {
  display: inline-flex;
  border-radius: 12px;
  text-align: center;
  line-height: 25px;
  margin: 0px 3px;
}

.icon_container {
  width: 30px;
  height: 30px;
  margin-left: -5px;
  border-radius: 50%;
  border: 1px solid white;
  color: white;
}

.icon_container_content {
  font-size: 18px;
}

.name_container {
  padding: 1px 3px
}

.name_container_content {
  font-size: 20px;
  font-weight: lighter;
}