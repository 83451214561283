.container {
  /* width: 60%; */
  /* min-height: 128px; */
  position: absolute;
  z-index: 1;
  /* border: 1px solid gray; */
  /* background-color: white; */
}

.toolbar {
  display: flex;
  border-bottom: rgb(217, 217, 217) solid 1px;
}
.toolbarButton {
  border-color: transparent;
  border-right-color: rgb(217, 217, 217);
}

.editor {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.flex1 {
  flex: 1;
  display: flex;
}